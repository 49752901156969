









































import BoardData from '@/pb/data/BoardData';
import MyPbxToolsApi from '@/common/MyPbxToolsApi';
import {Component, Vue} from 'vue-property-decorator';
import UserPermManager from "@/components/UserPermManager.vue";
import StatPicker from "@/components/pb/StatPicker.vue";
import BoardPreview from "@/components/BoardPreview.vue";
import Instance from "@/pb/data/Instance";
import InstanceWithBoards from "@/pb/data/InstanceWithBoards";
import EditDialog from "@/components/EditDialog.vue";

@Component({
    components: {EditDialog, BoardPreview, StatPicker, UserPermManager}
})
export default class Home extends Vue {
    get api(): MyPbxToolsApi {
        return MyPbxToolsApi.instance;
    }

    statDemo: string = "totalCalls";
    instances: InstanceWithBoards[] = [];
    items: { link: string, title: string }[] = [{
        link: "/pb/boards",
        title: "Powerboards"
    },
        {
            link: "/pb/instances",
            title: "Powerboard Instances"
        }
    ];

    mounted() {
        this.load();
        setTimeout(() => {
            const last = this.statDemo;
            this.statDemo = "";
            this.statDemo = last;
        }, 100)
    }

    load() {
        this.loadAsync().catch(err => console.error(err));
    }

    async loadAsync() {
        const rawBoards: BoardData[] = await MyPbxToolsApi.instance.doWbActionGeneric('board', 'list');
        const rawInstances: Instance[] = await MyPbxToolsApi.instance.doWbActionGeneric('instances', 'list');
        this.instances = rawInstances.map(instance => Object.assign({
            boards: rawBoards.filter(b => b.backendId === instance.id)
        }, instance));

    }
}
