






import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import BoardData from "@/pb/data/BoardData";
import BoardTileUtils from "@/pb/utils/BoardTileUtils";
import TileType from "@/pb/data/TileType";
import StatHelpers from "@/pb/StatHelpers";

@Component
export default class BoardPreview extends Vue {
    @Prop({type: Object, required: true})
    boardData!: BoardData;

    $refs!: {
        canvas: HTMLCanvasElement
    }

    @Watch('boardData', {deep: true, immediate: true})
    onBoardDataChanged() {
        if (this.$refs.canvas)
            this.draw();
    }
    mounted() {

        this.onResize();
        const resizeObserver = new ResizeObserver(this.onResize.bind(this));
        resizeObserver.observe(this.$refs.canvas);
    }

    onResize() {
        const dpr = window.devicePixelRatio || 1;
        const cvs = this.$refs.canvas;
        if (!cvs)
            return;
        const rect = cvs.getBoundingClientRect();
        cvs.width = rect.width * dpr;
        cvs.height = rect.height * dpr;

        cvs.getContext('2d')!.scale(dpr, dpr);
        this.draw();
    }

    draw() {
        const canv = this.$refs.canvas;
        const ctx = canv.getContext('2d')!;

        ctx.fillStyle = 'white';
        const fullWidth = canv.width, fullHeight = canv.height;
        const cellWidth = fullWidth / 8, cellHeight = fullWidth / 4;
        const outerTilePadding = canv.height / 64;
        const innerTilePadding = canv.height / 128;
        const font = 'Roboto';
        const tiles = this.boardData.tiles;
        ctx.clearRect(0, 0, fullWidth, fullHeight);

        for (const tile of tiles) {
            ctx.fillStyle = (tile.bgColor?.length ?? 0) > 0 ? tile.bgColor : "white";
            const
                tileX = tile.column * cellWidth + outerTilePadding,
                tileY = tile.row * cellHeight + outerTilePadding,
                tileWidth = tile.columns * cellWidth - outerTilePadding * 2,
                tileHeight = tile.rows * cellHeight - outerTilePadding * 2;
            ctx.fillRect(tileX, tileY, tileWidth, tileHeight);

            ctx.fillStyle = (tile.fgColor?.length ?? 0) > 0 ? tile.fgColor : "black";
            const baseTFontSize = Math.min(tileWidth / 8, tileHeight / 10) * tile.scale;
            ctx.font = `${baseTFontSize}px ${font}`;

            const text = BoardTileUtils.tileName(tile);
            const size = ctx.measureText(text);
            const textWidth = size.width;

            ctx.textBaseline = 'top';
            let headerSize = 0;
            if (BoardTileUtils.getShowTitle(tile)) {
                headerSize = baseTFontSize + 5;
                ctx.fillText(text, tileX + innerTilePadding + tileWidth / 2 - textWidth / 2, tileY + innerTilePadding, tileWidth - innerTilePadding * 2);
            }

            if (tile.type == TileType.ExtensionsTable && tile.tableColumns.length > 0) {
                const colWidth = tileWidth / tile.tableColumns.length;
                const colY = tileY + headerSize;
                const colHeight = tileHeight - headerSize;
                ctx.strokeStyle = tile.fgColor;
                const colTextSize = baseTFontSize / 6;

                for (let colIndex = 0; colIndex < tile.tableColumns.length; colIndex++) {
                    const col = tile.tableColumns[colIndex];

                    ctx.strokeStyle = tile.fgColor;
                    const colX = tileX + colIndex * colWidth;
                    ctx.strokeRect(colX, colY, colWidth, colHeight);

                    ctx.fillStyle = tile.fgColor;
                    ctx.font = `${colTextSize}px ${font}`;
                    const colText = StatHelpers.getStatFullNameByValue(col.columnType)!;
                    const size = ctx.measureText(colText);

                    ctx.fillText(colText, colX  + (colWidth / 2) - (size.width / 2), colY);
                }
            }
        }
    }
}
