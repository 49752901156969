







import Component from "vue-class-component";
import Vue from "vue";
import {Prop, Watch} from "vue-property-decorator";
import MyPbxToolsApi from "@/common/MyPbxToolsApi";
import BaseUserData from "@/common/data/BaseUserData";
import UserPerms from "@/common/UserPerms";

@Component
export default class UserPermManager extends Vue {
    @Prop({type: Number})
    userId!: number;
    userDetails: BaseUserData = {name: "", company: "", email: "", ipWhitelist: []};
    private perms: UserPerms = {
        manageUsers: false,
        admin: false
    };

    get api(): MyPbxToolsApi {
        return this.$store.state.api;
    }

    mounted() {
        this.refresh();
    }

    async refreshAsync() {
        this.userDetails = await MyPbxToolsApi.instance.getUserData(this.userId);
        this.perms = await MyPbxToolsApi.instance.doUserAction<UserPerms>('list_perms', {id: this.userId});
    }

    refresh() {
        this.refreshAsync().catch(console.error);
    }

    async syncPermAsync(value: boolean, perm: string, context: string = ""): Promise<void> {
        const verb = value ? 'add' : 'delete';
        await MyPbxToolsApi.instance.doUserAction(`${verb}_perm`, {id: this.userId, perm, context});
    }

    syncPerm(value: boolean, perm: string, context: string = "") {
        this.syncPermAsync(value, perm, context).catch(console.error)
    }

    @Watch('perms', {deep: true})

    onChanged(newPerm: UserPerms) {
        this.$store.commit('gotUserPerms', newPerm);
        this.syncPerm(newPerm.admin, 'admin');
    }

}
