import WbUtils from "@/pb/utils/WbUtils";
import StringUtils from "@/common/utils/StringUtils";
import BoardTile from "@/pb/data/BoardTile";
import TileType from "@/pb/data/TileType";

export default class BoardTileUtils {

    static getTileTypeName(tile: BoardTile): string {
        switch (tile.type) {
            case TileType.Stat:
                return StringUtils.humanize(tile.args[0]);
            case TileType.ExtensionChart:
                return `Extension Chart: ${StringUtils.humanize(tile.args[0])}`;
            case TileType.GenericPercentChart:
                return `Percent Chart: ${StringUtils.humanize(tile.args[0])} ÷ ${StringUtils.humanize(tile.args[1])}`;
            case TileType.PercentStatSummary:
                return `${StringUtils.humanize(tile.args[0]).replace('Percent', '')} Summary`;
            case TileType.BestExtension:
                return `Best ${StringUtils.humanize(tile.args[0])}`;
            default:

                return StringUtils.humanize(tile.type);
        }

    }

    static getShowTitle(tile: BoardTile): boolean {
        return tile.customTitle !== 'hide';
    }

    static setShowTitle(tile: BoardTile, show: boolean) {
        tile.customTitle = show ? '' : 'hide';
    }

    static getNamePostfix(tile: BoardTile): string {

        if (tile.filter.length === 0)
            return '';
        return `  for ${WbUtils.humaniseFilter(tile.filter)}`;
    }

    static tileName(tile: BoardTile): string {

        const baseName = (tile.customTitle?.length ?? 0) ? tile.customTitle : this.getTileTypeName(tile);
        return baseName + this.getNamePostfix(tile);
    }
}