enum TileType {

    ExtensionsTable = "extensionsTable",
    ExtensionChart = "extensionChart",
    ExportAbandonedForm = "exportAbandonedForm",
    Stat = "stat",
    BestExtension = "bestExtension",
    Heading = "heading",
    GenericPercentChart = "genericPercentChart",
    PercentStatSummary = "percentStatSummary",
}

export default TileType;