export default class WbUtils {

    static readonly filterPattern = /^([EGQX]):(.+)$/i;

    static humaniseFilter(filterText: string): string {
        if (filterText.includes(';')) {
            // multi filter
            return filterText.split(";")
                .map(this.humaniseFilter)
                .join(', ');
        }
        // TODO: Implement for other types
        const res = WbUtils.filterPattern.exec(filterText);

        if (res === null) {
            return `invalid filter: ${filterText}`;
        }
        return res[res.length - 1];
    }
}